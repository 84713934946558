// React Basic and Bootstrap
import React,{useState,useEffect} from "react";
import { Container, Row, Card, Col, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import {calcEst, showEst, optionsRequired} from '../common/sharedFunctions';

//Import Components

const Pricing = (props) => {

  const { t } = useTranslation();
  const settings = useSelector(state => state.settingsdata.settings);
  const cartypes = useSelector(state => state.cartypes);
  const [data, setData] = useState([]);
  const auth = useSelector(state => state.auth);
  const [loggedIn, setLoggedIn] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (cartypes.cars) {
      setData(cartypes.cars.sort((a, b) => a.pos - b.pos));
    } else {
      setData([]);
    }
  }, [cartypes.cars]);

  useEffect(()=>{
    if(auth.profile && auth.profile.uid){
      setLoggedIn(true);
    }else{
      setLoggedIn(false);
    }
  },[auth.profile]);

  const role = props.role;
  
  return (
    <React.Fragment>
      <section className="section">
        <Container>
          {/* section title */}
          <Row className="justify-content-center">
            <Col className="col-12 text-center">
              <div className="section-title mb-4 pb-2">
                <h4 className="title mb-4">Tarification</h4>
                <p className="text-muted para-desc mb-0 mx-auto">{t("Prices_Desc")}</p>
              </div>
            </Col>
          </Row>

          <Row className="align-items-center">
            {data.map((item, key) => (
              <Col key={key} md={4} className="col-12 mt-4 pt-2">
                <Card className={item.isActive ? "card pricing pricing-primary starter-plan bg-light py-5 border-0 rounded text-center" :
                  "card pricing pricing-primary bg-light py-5 border-0 rounded text-center"}>
                  <CardBody>
                    <h6 className="title name fw-bold text-uppercase mb-4">{item.name}</h6>
                    <div className="d-flex justify-content-center mb-4">
                      <span className="price h1 mb-0">{item.rate_per_unit_distance}</span>
                      <span className="h4 mb-0 mt-2">{settings.symbol}</span>
                      <span className="h4 align-self-end mb-1">/{settings.convert_to_mile ? t('mile') : t('km')}</span>
                    </div>

                    <ul className="list-unstyled mb-0 ps-0">
                      <li>{t('min_fare')}:{item.min_fare}</li>
                    
                      {/* {item.features.map((items, key) => (
                        <li key={key} className="h6 text-muted mb-0">
                          <span className="icon h5 me-2">
                            <i className="uil uil-check-circle align-middle"></i>
                          </span>
                          {items.title}
                        </li>
                      ))} */}
                    </ul>
                    
                    {/* <Link to="#" className="btn btn-primary mt-4">{item.buttonText}</Link> */}
                    <Link
                        variant="contained"
                        color="warning"
                        className="btn btn-primary mt-4"
                        // sx={{ width: '150px', fontSize: '16px', marginTop: 3 }}
                        onClick={(e) => { e.preventDefault(); 
                          if(loggedIn){
                          navigate(((role && role === 'driver' )|| (calcEst && !showEst && !optionsRequired && (role === 'admin' || role === 'fleetadmin' || role ==='woyomanager'))) ? '/bookings' : '/addbookings', 
                          { state: { carData: item }} )
                          }else{
                            navigate('/login')
                          }
                        }}
                      > 
                        { role === 'driver' || (calcEst && !showEst && !optionsRequired && (role === 'admin' || role === 'fleetadmin' || role ==='woyomanager')) ? t('info'): t('book_now')}
                      </Link>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}
export default Pricing;
