// React Basic and Bootstrap
import React from "react";
import { Container, Row, Col } from "reactstrap";

//Import Components
import SectionTitle from "../components/Shared/SectionTitle";
import FeatureBox from "./feature-box";
//import images
import featureImg from "../assets/images/app/secure.png";

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import VerifiedIcon from '@mui/icons-material/Verified';
import { useTranslation } from "react-i18next";
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import CarCrashOutlinedIcon from '@mui/icons-material/CarCrashOutlined';

const Feature = () => {  
    const { t } = useTranslation();
    const sectionItems = [
        {
          id: 1,
          icon:  <EmojiTransportationIcon style={{ color: '#ed6c02', fontSize: '30px' }} />,
          title:t('pruduct_section_heading_1'),
          desc: t('product_section_1'),
        },
        {
          id: 2,
          icon:  <AccessTimeIcon style={{ color: '#ed6c02', fontSize: '30px' }} />,
          title:t('pruduct_section_heading_2'),
          desc: t('product_section_2'),
        },
        {
          id: 3,
          icon:   <CarCrashOutlinedIcon style={{ color: '#ed6c02', fontSize: '30px'}} />,
          title:t('pruduct_section_heading_3'),
          desc: t('product_section_3'),
        },
        {
          id: 4,
          icon:  <VerifiedIcon style={{ color: '#ed6c02', fontSize: '30px' }} />,
          title:t('pruduct_section_heading_4'),
          desc: t('product_section_4'),
        },
      ];

    return (
      <React.Fragment>
        <section className="section bg-light">
          <Container>
            {/* section title */}
            <SectionTitle
              title={t("best_services")}
              desc={t("Service_Desc")}
            />

            <Row className="justify-content-center align-items-center">
              <Col lg="8" md="8">
                <Row className="mt-4 pt-2">
                  <FeatureBox appsFeatures={sectionItems} />
                </Row>
              </Col>
              <Col
                lg="4"
                md="4"
                xs="12"
                className="mt-4 pt-2 text-center text-md-end"
              >
                <img src={featureImg} className="img-fluid" alt="" />
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );  
}

export default Feature;