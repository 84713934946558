import React, { useEffect, useState } from 'react';
import { colors } from '../components/Theme/WebTheme';

// import generic component
import Section from "./Section";
import Feature from "./Feature";
import Pricing from "./Pricing";
import Testi from "./Testi";
import { useTranslation } from "react-i18next";
import moment from 'moment/min/moment-with-locales';

import { useSelector } from "react-redux";
import Topbar from "../components/Layout/Topbar";
import FooterWithoutMenuLightSocialOnly from 'components/Layout/FooterWithoutMenuLightSocialOnly';

const dashboardRoutes = [];

export default function LandingPage(props) {

  const auth = useSelector(state => state.auth);

  const { ...rest } = props;

  const [role, setRole] = useState();

  const languagedata = useSelector(state => state.languagedata);
  const settings = useSelector(state => state.settingsdata.settings);
  const [loggedIn, setLoggedIn] = useState(false);
  const [langSelection, setLangSelection] = useState();
  const [multiLanguage, setMultiLanguage] = useState();
  const [isActive, setIsActive] = useState(false);
  const { t, i18n } = useTranslation();

  const handleLanguageSelect = (event) => {
    i18n.addResourceBundle(multiLanguage[event].langLocale, 'translations', multiLanguage[event].keyValuePairs);
    i18n.changeLanguage(multiLanguage[event].langLocale);
    setLangSelection(event);
    moment.locale(multiLanguage[event].dateLocale);
    setIsActive(false);
  };

  useEffect(() => {
    if (languagedata.langlist) {
      for (const key of Object.keys(languagedata.langlist)) {
        if (languagedata.langlist[key].langLocale === i18n.language) {
          setLangSelection(key);
        }
      }
      setMultiLanguage(languagedata.langlist);
    }

  }, [languagedata.langlist]);

  useEffect(() => {
    if (auth.profile && auth.profile.uid) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [auth.profile]);

  useEffect(() => {
    if (auth && auth.profile) {
      if(auth.profile.uid){
        let role = auth.profile.usertype;
        setRole(role);
      }
    }
  }, [auth]);

  useEffect(() => {
    document.body.classList = "";
    window.addEventListener("scroll", scrollNavigation, true);
  });

  const scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById("topnav");
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add("nav-sticky");
      } else {
        navBar.classList.remove("nav-sticky");
      }
    }
  };
  return (
    <React.Fragment>
      {/*menu*/}
      <Topbar t={t} loggedIn={loggedIn} />
      {/* section */}
      <Section />

      <div className="position-relative">
        <div className="shape overflow-hidden text-light">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
    </div>
      </div>
      {/* Pricing */}
      <Pricing role={role} color={colors} />

      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      {/* Feature */}
      <Feature />


      {/* Testi */}
      <Testi />
      <div className="position-relative">
        <div className="shape overflow-hidden text-footer">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      
      <FooterWithoutMenuLightSocialOnly settings={settings}/>
    </React.Fragment>
  );
}
