import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

// Import images
import homeImage from "../assets/images/app/homeW.png";

const Section = () => {  
  const { t } = useTranslation();
  const settings = useSelector(state => state.settingsdata.settings);

    return (
      <React.Fragment>        
        <section className="bg-half-170 d-table w-100" id="home">
          <Container>
            <Row className="mt-5 align-items-center">
              <Col lg={6} md={7}>
                <div className="title-heading">
                  <h1 className="heading mb-3">
                   {t('WelcomeMsg')}{" "}
                    <span className="text-primary">WOYO</span>
                  </h1>
                  <p className="para-desc text-muted">
                  {t('about_us_content2')}
                  </p>
                  <div className="mt-4">
                    <Link to={settings.AppleStoreLink} className="btn btn-custom-orange mt-2 me-2">
                      <i className="uil uil-apple"></i> App Store
                    </Link>{" "}
                    <Link to={settings.PlayStoreLink} className="btn btn-outline-custom-orange mt-2">
                      <i className="uil uil-google-play"></i> Play Store
                    </Link>
                  </div>
                </div>
              </Col>
              <Col lg={6} md={5} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="text-md-end text-center ms-lg-4">
                  <img src={homeImage} className="img-fluid" alt="" />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );  
}

export default Section;
