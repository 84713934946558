import React from 'react';
import { Button ,Typography} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useTranslation } from "react-i18next";

const BackButton = ({ variant, onClick, style, size, childrenStyle,sx }) => {
    const { t } = useTranslation();
    return (
        <Button variant={variant?variant:"contained"}
            style={style}
            onClick={onClick}
            size={size}
            sx={sx}
            startIcon={<ArrowBackIosNewIcon />}>
            <Typography
                style={childrenStyle}>
                {t("go_back")}
            </Typography>

        </Button>
    );
};

export default BackButton;
