export const FirebaseConfig = {
	"projectId": "mali-woyo",
	"appId": "1:462714733476:web:4ddc36852db51536873731",
	"databaseURL": "https://mali-woyo-default-rtdb.europe-west1.firebasedatabase.app",
	"storageBucket": "mali-woyo.appspot.com",
	"locationId": "europe-west",
	"apiKey": "AIzaSyBle6zOQEGym7M81VHHS30H-acp8yaKfJk",
	"authDomain": "mali-woyo.firebaseapp.com",
	"messagingSenderId": "462714733476",
	"measurementId": "G-4ZNVGFXTN3"
};