import {
    FETCH_ALL_CONTRACTS,
    FETCH_ALL_CONTRACTS_SUCCESS,
    FETCH_ALL_CONTRACTS_FAILED,
    EDIT_CONTRACTS,
  } from "../store/types";
  import { firebase } from '../config/configureFirebase';
  import { onValue, set, push, remove } from "firebase/database";
  
  export const fetchContract = () => (dispatch) => {

    const {
        contractRef
    } = firebase;
  
    dispatch({
      type: FETCH_ALL_CONTRACTS,
      payload: null
    });
    onValue(contractRef, snapshot => {
      if (snapshot.val()) {
        let data = snapshot.val();
        const arr = Object.keys(data).map(i => {
          data[i].id = i;
          return data[i]
        });
        dispatch({
          type: FETCH_ALL_CONTRACTS_SUCCESS,
          payload: arr
        });
      } else {
        dispatch({
          type: FETCH_ALL_CONTRACTS_FAILED,
          payload: 'No contracts available.'
        });
      }
    });
  };
  
  export const editContract = (contract, method) => async (dispatch) => {
    const {
        contractRef, 
        contractEditRef,
    } = firebase;
    dispatch({
      type: EDIT_CONTRACTS,
      payload: { method, contract }
    });
    if (method === 'Add') {
      push(contractRef, contract);
    } else if (method === 'Delete') {
      remove(contractEditRef(contract.id));
    }
     else {
      set(contractEditRef(contract.id), contract);
    }
  }
  