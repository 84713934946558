export const FareCalculator = (distance,time,rateDetails,instructionData, decimal,isDriverType = false)=>{  

    let baseCalculated =  (parseFloat(rateDetails.rate_per_unit_distance) * parseFloat(distance)) + (parseFloat(rateDetails.rate_per_hour) * (parseFloat(time) / 3600));
    if(rateDetails.base_fare>0){
        baseCalculated = baseCalculated + rateDetails.base_fare;
    }
    if(instructionData && instructionData.parcelTypeSelected){
        baseCalculated = baseCalculated + instructionData.parcelTypeSelected.amount;
    }
    if(instructionData && instructionData.optionSelected){
        baseCalculated = baseCalculated + instructionData.optionSelected.amount;
    }
    let total = baseCalculated > parseFloat(rateDetails.min_fare) ? baseCalculated : parseFloat(rateDetails.min_fare);
    let convenienceFee = 0;
    if(rateDetails.convenience_fee_type && rateDetails.convenience_fee_type == 'flat' ||
        (rateDetails.fee_type_partner && rateDetails.fee_type_partner == 'flat' && !isDriverType)
    ){
        convenienceFee = isDriverType ? rateDetails.convenience_fees : rateDetails.fee_partner;
    }else{
        convenienceFee = isDriverType 
                                ? (total*parseFloat(rateDetails.convenience_fees)/100)
                                : (total*parseFloat(rateDetails.fee_partner)/100);
    }
    let grand = total + convenienceFee;

    let cost = parseFloat(total.toFixed(decimal));
	let lastDigitOfCost = Math.floor(cost % 10);
	let newCost =
		lastDigitOfCost === 0 || lastDigitOfCost === 5
			? cost
			: lastDigitOfCost < 5
				? cost + (5 - lastDigitOfCost)
				: cost + (10 - lastDigitOfCost);

	let fees = parseFloat(convenienceFee.toFixed(decimal));
     
	let lastDigitOfFees = Math.floor(fees % 10);
	let newFees =
		lastDigitOfFees === 0 || lastDigitOfFees === 5
			? fees
			: lastDigitOfFees < 5
				? fees + (5 - lastDigitOfFees)
				: fees + (10 - lastDigitOfFees);


    return {
                    totalCost: newCost,
                    grandTotal: newCost,
                    convenience_fees: newFees,
                };
     
}
