import React, { useState, useEffect, useRef } from "react";
import { downloadCsv } from "../common/sharedFunctions";
import MaterialTable from "material-table";
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { api } from "common";
import { useTranslation } from "react-i18next";
import moment from "moment/min/moment-with-locales";
import { colors } from "../components/Theme/WebTheme";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';

export const MAIN_COLOR = colors.TAXIPRIMARY;
export const SECONDORY_COLOR = colors.TAXISECONDORY;

export default function Contracts() {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const auth = useSelector(state => state.auth);
  const settings = useSelector((state) => state.settingsdata.settings);
  const { editContract } = api;
  const [data, setData] = useState([]);
  const contractdata = useSelector((state) => state.contractdata);
  const role = useSelector(state => state.auth.profile.usertype);
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  useEffect(() => {
    if (contractdata.contracts) {
      setData(contractdata.contracts);
    } else {
      setData([]);
    }
  }, [contractdata.contracts]);

  const columns = [
    {
      title: t("createdAt"),
      field: "createdAt",
      editable: "never",
      defaultSort: "desc",
      hidden:role ==="admin"?false:true,
      render: (rowData) =>
        rowData.createdAt ? moment(rowData.createdAt).format("lll") : null,
    },
    {
      title: "Début",
      field: "at",
      editComponent: (props) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            inputRef={inputRef}
            renderInput={(props) => <TextField {...props} />}
            label="DateTimePicker"
            value={props.rowData.at ? props.rowData.at : new Date()}
            onChange={(newValue) => {
              props.onChange(newValue);
            }}
            autoFocus={false}
          />
        </LocalizationProvider>
      ),
      render: (rowData) =>
        rowData.at ? moment(rowData.at).format("lll") : null,
    },
    {
      title: "Fin",
      field: "from",
      editComponent: (props) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            inputRef={inputRef}
            renderInput={(props) => <TextField {...props} />}
            label="DateTimePicker"
            value={props.rowData.from ? props.rowData.from : new Date()}
            onChange={(newValue) => {
              props.onChange(newValue);
            }}
            autoFocus={false}
          />
        </LocalizationProvider>
      ),
      render: (rowData) =>
        rowData.from ? moment(rowData.from).format("lll") : null,
    },
    {
      title: t("last_name"),
      field: "name",
      initialEditValue: "",
      cellStyle: { paddingLeft: isRTL === "rtl" ? 30 : 15 },
    },
    {
      title: "Office",
      field: "office",
      initialEditValue: "",
      cellStyle: { paddingLeft: isRTL === "rtl" ? 30 : 15 },
    },

    {
      title: "Chauffeur",
      field: "driver",
      cellStyle: { paddingLeft: isRTL === "rtl" ? 30 : 15 },
    },
    {
      title: "Montant",
      field: "amount",
      initialEditValue: 0,
      cellStyle: { textAlign: "center" },
    },
    {
      title: "Payé",
      field: "amountdue",
      initialEditValue: 0,
      cellStyle: { textAlign: "center" },
    },
    {
      title: "Restant",
      field: "amountrest",
      initialEditValue: 0,
      editable: "never",
      cellStyle: { textAlign: "center" },
    },
    {
      title: "Statut",
      field: "hasdone",
      type: 'boolean',
      editable: "never",
      render: (rowData) => (
        rowData.hasdone ? <VerifiedRoundedIcon fontSize="large" color="success" /> : <ErrorRoundedIcon fontSize="large" color="warning" />
      ),
      cellStyle: { textAlign: "center" },
    },
  ];

  const [selectedRow, setSelectedRow] = useState(null);

  return contractdata.loading ? (
    <CircularLoading />
  ) : (
    <MaterialTable
      title={"Contrats"}
      columns={columns}
      style={{ 
                    direction: isRTL === "rtl" ? "rtl" : "ltr",
                    borderRadius: "8px",
                    boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
                    padding:5 
                }}
      data={data}
      onRowClick={(evt, selectedRow) =>
        setSelectedRow(selectedRow.tableData.id)
      }
      options={{
        exportCsv: (columns, data) => {
          let hArray = [];
          const headerRow = columns.map((col) => {
            if (typeof col.title === "object") {
              return col.title.props.text;
            }
            hArray.push(col.field);
            return col.title;
          });
          const dataRows = data.map(({ tableData, ...row }) => {
            row.createdAt =
              new Date(row.createdAt).toLocaleDateString() +
              " " +
              new Date(row.createdAt).toLocaleTimeString();
            let dArr = [];
            for (let i = 0; i < hArray.length; i++) {
              dArr.push(row[hArray[i]]);
            }
            return Object.values(dArr);
          });
          const { exportDelimiter } = ",";
          const delimiter = exportDelimiter ? exportDelimiter : ",";
          const csvContent = [headerRow, ...dataRows]
            .map((e) => e.join(delimiter))
            .join("\n");
          const csvFileName = "download.csv";
          downloadCsv(csvContent, csvFileName);
        },
        exportButton: {
          csv: settings.AllowCriticalEditsAdmin,
          pdf: true,
        },
        maxColumnSort: "all_columns",
        rowStyle: (rowData) => ({
          backgroundColor:
            selectedRow === rowData.tableData.id ? "#EEE" : "#FFF",
        }),
        editable: {
          backgroundColor: colors.Header_Text,
          fontSize: "0.8em",
          fontWeight: "bold ",
          fontFamily: 'Lucida Console", "Courier New", monospace',
        },
        headerStyle: {
          position: "sticky",
            top: "0px",
            fontSize: "0.8em",
            fontWeight: "bold ",
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            color: colors.BLACK,
            backgroundColor: SECONDORY_COLOR,
            textAlign: "center",
            border: "1px solid rgba(224, 224, 224, 1)",
        },
      }}
      localization={{
        body: {
          addTooltip: t("add"),
          deleteTooltip: t("delete"),
          editTooltip: t("edit"),
          emptyDataSourceMessage: t("blank_message"),
          editRow: {
            deleteText: t("delete_message"),
            cancelTooltip: t("cancel"),
            saveTooltip: t("save"),
          },
        },
        toolbar: {
          searchPlaceholder: t("search"),
          exportTitle: t("export"),
        },
        header: {
          actions: t("actions"),
        },
        pagination: {
          labelDisplayedRows: "{from}-{to} " + t("of") + " {count}",
          firstTooltip: t("first_page_tooltip"),
          previousTooltip: t("previous_page_tooltip"),
          nextTooltip: t("next_page_tooltip"),
          lastTooltip: t("last_page_tooltip"),
        },
      }}
      editable={{
        onRowAdd: (newData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              if (newData && newData.name && newData.driver && newData.amount) {
                newData["createdAt"] = new Date().getTime();
                newData["at"] = newData.at
                  ? new Date(newData.at).getTime()
                  : null;
                newData["from"] = newData.from
                  ? new Date(newData.from).getTime()
                  : null;
                  newData["amountrest"]=newData.amount >0 && newData.amountdue > 0
                    ? Number(newData.amount - newData.amountdue)
                    : newData.amountrest;
                    newData["hasdone"]=newData.amount >0 && newData.amountdue>0 && newData.amountrest===0
                    ? true
                    : false;
                dispatch(editContract(newData, "Add"));
                resolve();
              } else {
                alert("Remplir les informations du contrat");
                reject();
              }
            }, 600);
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              resolve();
              if (newData !== oldData) {
                delete newData.tableData;
                  newData["at"] = newData.at
                    ? new Date(newData.at).getTime()
                    : null;
                  newData["from"] = newData.from
                    ? new Date(newData.from).getTime()
                    : null;
                    newData["amountrest"]=newData.amount >0 && newData.amountdue > 0
                    ? Number(newData.amount - newData.amountdue)
                    : newData.amountrest;
                    newData["hasdone"]=newData.amount >0 && newData.amountdue>0 && newData.amountrest===0
                    ? true
                    : false;
                  dispatch(editContract(newData, "Update"));
              }
            }, 600);
          }),
        onRowDelete: auth && auth.profile && (auth.profile.usertype === "admin")? (oldData) =>
          settings.AllowCriticalEditsAdmin
            ? new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  dispatch(editContract(oldData, "Delete"));
                }, 600);
              })
            : new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  alert(t("demo_mode"));
                }, 600);
              }):null,
      }}
    />
  );
}
