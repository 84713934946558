import {
  FETCH_WITHDRAWS,
  FETCH_WITHDRAWS_SUCCESS,
  FETCH_WITHDRAWS_FAILED,
  EDIT_WITHDRAWS
} from "../store/types";
import { firebase } from '../config/configureFirebase';
import { get, onValue, child, update } from "firebase/database";
import { RequestPushMsg } from "../other/NotificationFunctions";
import store from "../store/store";

export const fetchWithdraws = () => (dispatch) => {

  const {
    withdrawRef
  } = firebase;

  dispatch({
    type: FETCH_WITHDRAWS,
    payload: null
  });
 onValue(withdrawRef, snapshot => {
    if (snapshot.val()) {
      const data = snapshot.val();
      const arr = Object.keys(data).map(i => {
        data[i].id = i
        return data[i]
      });
      dispatch({
        type: FETCH_WITHDRAWS_SUCCESS,
        payload: arr.reverse()
      });
    } else {
      dispatch({
        type: FETCH_WITHDRAWS_FAILED,
        payload: "No WITHDRAWS available."
      });
    }
  });
};

// export const completeWithdraw = (entry) => (dispatch) => {

//   const {
//     withdrawRef,
//     walletHistoryChildRef,
// 		walletHistoryUpdateRef,
// 		singleUserRef
//   } = firebase;
  
//   dispatch({
//     type: EDIT_WITHDRAWS,
//     payload: entry
//   });
//   update(child(withdrawRef, entry.id),{ ...entry, processed: true, processDate: new Date().getTime() });
//   onValue(singleUserRef(entry.uid), snapshot => {
// 		if (snapshot.val()) {
// 			const data = snapshot.val().walletBalance;
// 			let walletBal = parseFloat(data) - parseFloat(entry.amount);
// 			update(singleUserRef(entry.uid), { walletBalance: walletBal });

// 			onValue(walletHistoryChildRef(entry.uid, entry.id), snapshot => {
// 				if (snapshot.val()) {
// 						let data = snapshot.val();
// 							for (const key in data) { 
// 								if(data[key].transaction_id === entry.id){
// 										update(walletHistoryUpdateRef(entry.uid,key),{status:'DONE'}).then(()=>{
// 											RequestPushMsg(data.pushToken, {
// 												title:
// 													store.getState().languagedata.defaultLanguage
// 														.notification_title,
// 												msg: store.getState().languagedata.defaultLanguage.demandeRetraitOK,
// 												screen: "Wallet",
// 												ios: data.userPlatform === "IOS" ? true : false,
// 											});
// 										});
// 								}
// 						}
// 				} else {
// 						console.log("Je ne marche pas");
// 				}
// 		});
// 		} else {	
// 			console.log("No WITHDRAWS available");
// 		}
// 	},{onlyOnce:true});
// }

export const completeWithdraw = (entry) => async (dispatch) => {
  const {
    withdrawRef,
    walletHistoryChildRef,
    walletHistoryUpdateRef,
    singleUserRef,
  } = firebase;
  
  try {
  dispatch({
    type: EDIT_WITHDRAWS,
      payload: entry,
  });

    // Mettre � jour l'entr�e de retrait
    await update(child(withdrawRef, entry.id), {
      ...entry,
      processed: true,
      processDate: new Date().getTime(),
    });

    // R�cup�rer les donn�es de l'utilisateur
    const userSnapshot = await get(singleUserRef(entry.uid));
    if (userSnapshot.exists()) {
      const user = userSnapshot.val();
      const walletBalance = parseFloat(user.walletBalance);
      const newWalletBalance = walletBalance - parseFloat(entry.amount);

      // Mettre � jour le solde du portefeuille de l'utilisateur
      await update(singleUserRef(entry.uid), { walletBalance: newWalletBalance });

      // R�cup�rer les donn�es d'historique du portefeuille
      const walletHistorySnapshot = await get(walletHistoryChildRef(entry.uid, entry.id));
      if (walletHistorySnapshot.exists()) {
        const data = walletHistorySnapshot.val();
        for (const key in data) {
          if (data[key].transaction_id === entry.id) {
            await update(walletHistoryUpdateRef(entry.uid, key), { status: 'DONE' });

            // Envoyer une notification push
            RequestPushMsg(user.pushToken, {
              title: store.getState().languagedata.defaultLanguage.notification_title,
              msg: store.getState().languagedata.defaultLanguage.demandeRetraitOK,
              screen: "Wallet",
              ios: data.userPlatform === "IOS" ? true : false,
            });
            console.log("nofitication sent.");
          }
        }
      } else {
        console.log("No wallet history available for this transaction.");
      }
    } else {
      console.log("User not found.");
    }
  } catch (error) {
    console.error("An error occurred while completing the withdraw process:", error);
  }
};
