// React Basic and Bootstrap
import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  CardBody,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { FirebaseContext, api } from 'common';
import Icon from "@mui/material/Icon";
import AlertDialog from '../components/AlertDialog';
// import Button from "../components/CustomButtons/Button.js";

import Typography from '@mui/material/Typography';
import { colors } from '../components/Theme/WebTheme';

//Import Icons
import FeatherIcon from "feather-icons-react";
// import images
import loginImg from "../assets/images/user/login.svg";
import signup from "../assets/images/user/signup1.svg";


// Formik Validation
import { useTranslation } from "react-i18next";

import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import { GoogleLogin } from '@react-oauth/google';
import CountrySelect from '../components/CountrySelect';
import { makeStyles } from "@mui/styles";
import InputAdornment from "@mui/material/InputAdornment";
import CustomInput from "../components/CustomInput/CustomInput.js";
import styles from '../styles/loginPage.js';

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

const useStyles = makeStyles(styles);

export default function LoginPageCustom(props) {

  const { authRef, RecaptchaVerifier, signInWithPhoneNumber } = useContext(FirebaseContext);
  const { t } = useTranslation();
  const {
    googleLogin,
    clearLoginError,
    mobileSignIn,
    countries,
    sendResetMail,
    verifyEmailPassword,
    requestMobileOtp,
    verifyMobileOtp,
    mainSignUp,
    checkUserExists,
    validateReferer
  } = api;
  const navigate = useNavigate();
  const auth = useSelector(state => state.auth);
  const settings = useSelector(state => state.settingsdata.settings);
  const dispatch = useDispatch();
  const [capatchaReady, setCapatchaReady] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [verifier, setVerifier] = useState(null);
  const [mobileWithoutCountry, setMobileWithoutCountry] = useState('');
  const [showSignUp, setShowSignUp] = useState(false);
  const [message, setMessage] = useState(false)
  const [showPassword, setShowPassword] = useState(true)
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);

  const [data, setData] = React.useState({
    email: '',
    confirmpassword: "",
    country: "",
    contact: '',
    mobile: "",
    password: '',
    otp: '',
    verificationId: null,
    firstName: '',
    lastName: '',
    selectedcountry: null,
    usertype: 'customer',
    referralId: '',
    entryType: null,
    sendBill: false,
    isWoyoDriver:false,
    isSpecificUser:false
  });

  const [otpCalled, setOtpCalled] = useState();

  const [commonAlert, setCommonAlert] = useState({ open: false, msg: '' });

  const classes = useStyles();
  const { ...rest } = props;

  useEffect(() => {
    if (settings) {
      for (let i = 0; i < countries.length; i++) {
        if (countries[i].label === settings.country) {
          setData({
            country: countries[i].phone,
            selectedcountry: countries[i],
          });
        }
      }
    }
  }, [settings, countries]);

  useEffect(() => {
    if (!window.recaptchaVerifier) {
      setCapatchaReady(true);
    }
    if (auth.profile) {
      if (auth.profile.uid) {
        let role = auth.profile.usertype;
        if (role === 'admin' || role === 'fleetadmin' || role === 'woyomanager') {
          navigate('/dashboard');
        }
        // else if (role === 'driver') {
        //   navigate('/bookings');
        // }
        else {
          // navigate('/');
          navigate('/bookings');
        }
      }
    }
    if (auth.error && auth.error.flag && auth.error.msg.message !== t('not_logged_in')) {
      if (auth.error.msg.message === t('require_approval')) {
        setCommonAlert({ open: true, msg: t('require_approval') })
      } else if (auth.error.msg.message === "Firebase: Error (auth/invalid-verification-code).") {
        setCommonAlert({ open: true, msg: t('login_error') });
        setIsLoading(false);
      } else {
        if (data.contact && (data.contact === '' || !(!data.contact))) {
          setCommonAlert({ open: true, msg: t('login_error') });
          setIsLoading(false);
        }
      }
    }
    if (auth.verificationId && otpCalled) {
      setOtpCalled(false);
      setData({ ...data, verificationId: auth.verificationId });
    }
  }, [auth.profile, auth.error, auth.verificationId, navigate, data, data.email, data.contact, capatchaReady, RecaptchaVerifier, t, setData, otpCalled, setOtpCalled]);


  const handleGoogleLogin = (credentialResponse) => {
    if (credentialResponse && credentialResponse.credential) {
      dispatch(googleLogin(credentialResponse.credential, null))
    } else {
      setCommonAlert({ open: true, msg: t('login_error') })
      setIsLoading(false);
    }
  }

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: '' });
    if (auth.error.flag) {
      setData({ ...data, email: '', pass: '' });
      dispatch(clearLoginError());
    }
  };

  const onInputChange = (event) => {
    setData({ ...data, [event.target.id]: event.target.value })
  }

  const handleGetOTP = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (data.country) {
      if (data.contact) {
        if (isNaN(data.contact)) {
          setData({ ...data, entryType: 'email' });
          const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          if (re.test(data.contact)) {
            await dispatch(verifyEmailPassword(
              data.contact,
              data.otp
            ));
          } else {
            setCommonAlert({ open: true, msg: t('proper_email') })
            setIsLoading(false);
          }
        } else {
          setData({ ...data, entryType: 'mobile' });
          let formattedNum = data.contact.replace(/ /g, '');
          const phoneNumber = "+" + data.country + formattedNum;
          if (formattedNum.length > 6) {
            if (settings.customMobileOTP) {
              dispatch(requestMobileOtp(phoneNumber));
              setData({ ...data, verificationId: true })
              setIsLoading(false)
            } else {
              if (!window.recaptchaVerifier || verifier === null) {
                window.recaptchaVerifier = await new RecaptchaVerifier(authRef(), 'sign-in-button', {
                  'size': 'invisible',
                  'callback': function (response) {
                    setCapatchaReady(true);
                  }
                });
              }
              const appVerifier = window.recaptchaVerifier;
              setVerifier(appVerifier);
              await signInWithPhoneNumber(authRef(), phoneNumber, appVerifier)
                .then(res => {
                  setData({ ...data, verificationId: res.verificationId })
                  setIsLoading(false)
                  window.recaptchaVerifier.clear();
                })
                .catch(error => {
                  setCommonAlert({ open: true, msg: error.code + ": " + error.message });
                  setIsLoading(false);
                });
            }
          } else {
            setCommonAlert({ open: true, msg: t('mobile_no_blank_error') })
            setIsLoading(false);
          }
        }
      } else {
        setCommonAlert({ open: true, msg: t('contact_input_error') })
        setIsLoading(false);
      }
    } else {
      setCommonAlert({ open: true, msg: t('country_blank_error') })
      setIsLoading(false);
    }
  }


  const handleVerifyOTP = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (data.otp && data.otp.length === 6) {
      if (settings.customMobileOTP) {
        let formattedNum = data.contact.replace(/ /g, '');
        const phoneNumber = "+" + data.country + formattedNum;
        dispatch(verifyMobileOtp(
          phoneNumber,
          data.otp
        ));
      } else {
        await dispatch(mobileSignIn(
          data.verificationId,
          data.otp
        ));
      }
      setIsLoading(false);
    } else {
      setCommonAlert({ open: true, msg: t('otp_validate_error') })
      setIsLoading(false);
    }
  }

  const handleCancel = (e) => {
    setData({
      ...data,
      contact: null,
      verificationId: null
    });
    setIsLoading(false);
  }

  const onCountryChange = (object, value) => {
    if (value && value.phone) {
      setData({ ...data, country: value.phone, selectedcountry: value });
      setMobileWithoutCountry("")
    }
  };

  const forgotPassword = async (e) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (re.test(data.contact)) {
      await dispatch(sendResetMail(data.contact));
      setCommonAlert({ open: true, msg: t('reset_pass_msg') });
    } else {
      setCommonAlert({ open: true, msg: t('proper_email') });
    }
  };

  const handleShowSignup = () => {
    setData({ ...data, email: "", firstName: "", lastName: "", password: "", confirmpassword: "", usertype: "customer", referralId: "" })
    setMobileWithoutCountry("")
    setShowSignUp(!showSignUp)
  }

  const mobileInputChange = (e) => {
    const val = e.target.value;
    setMobileWithoutCountry(val)
    let formattedNum = val.replace(/ /g, '');
    const phoneNumber = "+" + data.country + formattedNum;
    setData({ ...data, mobile: phoneNumber })
  }

  const handleChangeUser = (event) => {
    setData({ ...data, usertype: event.target.value });
  };
  const checkPasswordValidity = value => {
    if (value !== data.confirmpassword) {
      return (t('confirm_password_not_match_err'));
    }

    const isNonWhiteSpace = /^\S*$/;
    if (!isNonWhiteSpace.test(value)) {
      return (t('password_must_not_contain_whitespaces'));
    }

    const isContainsUppercase = /^(?=.*[A-Z]).*$/;
    if (!isContainsUppercase.test(value)) {
      return (t('password_must_have_at_least_one_uppercase_character'));
    }

    const isContainsLowercase = /^(?=.*[a-z]).*$/;
    if (!isContainsLowercase.test(value)) {
      return (t('password_must_have_at_least_one_lowercase_character'));
    }

    const isContainsNumber = /^(?=.*[0-9]).*$/;
    if (!isContainsNumber.test(value)) {
      return (t('password_must_contain_at_least_one_digit'));
    }

    const isValidLength = /^.{8,16}$/;
    if (!isValidLength.test(value)) {
      return (t('password_must_be_8-16_characters_long'));
    }

    return null;
  }

  const validateMobile = () => {
    let mobileValid = true;
    if (mobileWithoutCountry.length < 6) {
      mobileValid = false;
      setCommonAlert({ open: true, msg: t('mobile_no_blank_error') })
    }
    return mobileValid;
  }


  const handleSignUp = (e) => {
    e.preventDefault();
    new Promise((resolve, reject) => {
      setIsLoading(true)
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      const validatePassword = checkPasswordValidity(data.password);
      if (re.test(data.email)) {
        if (/\S/.test(data.firstName) && data.firstName.length > 0 && /\S/.test(data.lastName) && data.lastName.length > 0) {
          if (!validatePassword) {
            if (validateMobile()) {
              const userData = { ...data };
              if (userData.usertype === 'customer') delete userData.carType
              delete userData.confirmpassword;
              delete userData.pass;
              checkUserExists(userData).then((res) => {
                if (res.users && res.users.length > 0) {
                  setCommonAlert({ open: true, msg: t('user_exists') })
                  setIsLoading(false);
                } else {
                  if (userData.referralId && userData.referralId.length > 0) {
                    validateReferer(userData.referralId).then((referralInfo) => {
                      if (referralInfo.uid) {
                        mainSignUp({ ...userData, signupViaReferral: referralInfo.uid }).then((res) => {
                          if (res.uid) {
                            setData({ ...data, email: "", firstName: "", lastName: "", password: "", confirmpassword: "", usertype: "customer", referralId: "" })
                            setMobileWithoutCountry("")
                            setMessage(true)
                            setIsLoading(false);
                            resolve();
                          } else {
                            setCommonAlert({ open: true, msg: t('reg_error') })
                            setIsLoading(false);
                          }
                        })
                      } else {
                        setCommonAlert({ open: true, msg: t('referer_not_found') })
                        setIsLoading(false);
                      }
                    }).catch((error) => {
                      setCommonAlert({ open: true, msg: t('referer_not_found') })
                      setIsLoading(false);
                    });
                  } else {
                    mainSignUp(userData).then((res) => {
                      if (res.uid) {
                        setData({ ...data, email: "", firstName: "", lastName: "", password: "", confirmpassword: "", usertype: "customer", referralId: "" })
                        setMobileWithoutCountry("")
                        setMessage(true)
                        setIsLoading(false);
                        resolve();
                      } else {
                        setCommonAlert({ open: true, msg: t('reg_error') })
                        setIsLoading(false);
                      }
                    })
                  }
                }
              })
            } else {
              setCommonAlert({ open: true, msg: t('mobile_no_blank_error') })
              reject();
            }
          } else {
            setCommonAlert({ open: true, msg: validatePassword })
            reject();
          }
        } else {
          setCommonAlert({ open: true, msg: t('proper_input_name') })
          reject();
        }
      } else {
        setCommonAlert({ open: true, msg: t('proper_email') })
        reject();
      }
    }).catch(() => { })
      .finally(() => { setIsLoading(false) })
  }

  return (
    <React.Fragment>
      <div className="back-to-home">
        <Link to="/" className="back-button btn btn-icon btn-primary">
          <i>
            <FeatherIcon icon="arrow-left" className="icons" />
          </i>
        </Link>
      </div>
      <section className="bg-home d-flex align-items-center">
        <Container>
          <Row className="align-items-center">
            <Col lg={showSignUp ? 5 : 7} md={6}>
              <div className="me-lg-5">
                <img
                  src={showSignUp ? signup : loginImg}
                  className="img-fluid d-block mx-auto"
                  alt=""
                />
              </div>
            </Col>
            <Col lg={showSignUp ? 7 : 5} md={6}>
              <Card className="login-page bg-white shadow rounded border-0">
                <CardBody>
                  <div className="card-title text-center">
                    <Typography variant="h6" noWrap component="div" style={{ color: '#fff' }}>
                      <>
                        <Typography
                          variant="h3"
                          style={{ display: "inline", color: colors.Orange, fontWeight: 500 }}
                        >
                          WO
                        </Typography>
                        <Typography
                          variant="h3"
                          style={{ display: "inline", color: colors.Deep_Blue, fontWeight: 500 }}
                        >
                          YO
                        </Typography>
                      </>
                    </Typography>
                    <h4 className="mb-4">{!showSignUp ? t('login') : t('signup')}</h4>
                  </div>

                  {showSignUp ?
                    <form
                      className="login-form mt-4"
                    >
                      <Row>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          <div className="mb-3">
                            <CustomInput
                              labelText={t("first_name")}
                              id="firstName"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                required: true,
                                endAdornment: (
                                  <InputAdornment position="start">
                                    <AccountBoxIcon
                                      className={classes.inputIconsColor}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                              onChange={onInputChange}
                              value={data.firstName}
                            />
                          </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          <div className="mb-3">
                            <CustomInput
                              labelText={t("last_name")}
                              id="lastName"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                required: true,
                                endAdornment: (
                                  <InputAdornment position="start">
                                    <AccountBoxIcon
                                      className={classes.inputIconsColor}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                              onChange={onInputChange}
                              value={data.lastName}
                            />
                          </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          <div className="mb-3">
                            {data.selectedcountry && settings.mobileLogin && countries && countries.length > 0 ?
                              <CountrySelect
                                countries={countries}
                                label={t('select_country')}
                                value={data.selectedcountry}
                                onChange={onCountryChange}
                                style={{ paddingTop: 20 }}
                                disabled={data.verificationId ? true : false}
                                readOnly={!settings.AllowCountrySelection}
                              />
                              : null}
                          </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          <div className="mb-3">
                            <CustomInput
                              labelText={t("phone")}
                              id="mobileWithoutCountry"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                required: true,
                                endAdornment: (
                                  <InputAdornment position="start">
                                    <LocalPhoneIcon
                                      className={classes.inputIconsColor}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                              onChange={mobileInputChange}
                              value={mobileWithoutCountry}
                            />
                          </div>
                        </Col>



                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          <div className="mb-3">
                            <FormControl variant="standard" fullWidth>
                              <InputLabel
                                id="demo-simple-select-label"
                              >
                                {t("User Type")}
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={data.usertype || ""}
                                label={t("approved")}
                                onChange={handleChangeUser}
                                className={classes.selectField}
                                aria-label="userType"
                              >
                                <MenuItem value={"customer"}>{t("customer")}</MenuItem>
                                <MenuItem value={"driver"}>{t("driver")}</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </Col>


                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          <div className="mb-3">
                            <CustomInput
                              labelText={t("email")}
                              id="email"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                required: true,
                                endAdornment: (
                                  <InputAdornment position="start">
                                    <EmailIcon
                                      className={classes.inputIconsColor}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                              onChange={onInputChange}
                              value={data.email}
                            />
                          </div>
                        </Col>


                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          <div className="mb-3">
                            <CustomInput
                              labelText={t("password")}
                              id="password"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                required: true,
                                type: !showPassword ? "text" : "password",
                                endAdornment: (
                                  <InputAdornment position="start">
                                    {!showPassword ? (
                                      <VisibilityIcon
                                        className={classes.inputIconsColor}
                                        onClick={() => setShowPassword(!showPassword)} sx={{ cursor: 'pointer' }}
                                      />
                                    ) : (
                                      <VisibilityOffIcon
                                        className={classes.inputIconsColor}
                                        onClick={() => setShowPassword(!showPassword)} sx={{ cursor: 'pointer' }}
                                      />
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                              onChange={onInputChange}
                              value={data.password}
                            />
                          </div>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          <div className="mb-3">
                            <CustomInput
                              labelText={t("confirm_password")}
                              id="confirmpassword"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                required: true,
                                type: !showConfirmPassword ? "text" : "password",
                                endAdornment: (
                                  <InputAdornment position="start">
                                    {!showConfirmPassword ? (
                                      <VisibilityIcon
                                        className={classes.inputIconsColor} sx={{ cursor: 'pointer' }}
                                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                      />
                                    ) : (
                                      <VisibilityOffIcon
                                        className={classes.inputIconsColor} sx={{ cursor: 'pointer' }}
                                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                      />
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                              onChange={onInputChange}
                              value={data.confirmpassword}
                            />
                          </div>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <div className="mb-3">
                            <CustomInput
                              labelText={t("referralId")}
                              id="referralId"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    <AccountBoxIcon
                                      className={classes.inputIconsColor}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                              onChange={onInputChange}
                              value={data.referralId}
                            />
                          </div>
                        </Col>

                        <Col md={12}>
                          <div className="d-grid">
                            {message ? (
                              <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                                <Typography style={{ color: colors.GREEN, fontWeight: "bold" }}>{t("account_create_successfully")}</Typography>
                                <Button simple color="primary" onClick={() => { setShowSignUp(false) }}>
                                  {t('signIn')}
                                </Button>
                              </div>
                            ) : isLoading ? (
                              <DialogActions
                                style={{
                                  justifyContent: "center",
                                  alignContent: "center",
                                }}
                              >
                                <CircularProgress />
                              </DialogActions>
                            ) : (
                              <>
                                <Button simple color="primary" type="submit" onClick={handleSignUp}>
                                  {t('signup')}
                                </Button>
                                {/* <Button className={classes.normalButton} simple color="info" size="lg" onClick={handleShowSignup}>
                                    {t('login')}
                                  </Button> */}
                              </>
                            )}
                          </div>
                        </Col>

                        <div className="mx-auto">
                          <p className="mb-0 mt-3">
                            <small className="text-dark me-2">
                              {t('Already_account')}
                            </small>{" "}
                            <Link
                              onClick={handleShowSignup}
                              className="text-dark fw-bold"
                              simple
                            >
                              {t('login')}
                            </Link>
                          </p>
                        </div>
                      </Row>
                    </form>
                    :
                    <>
                      <div id="sign-in-button" />
                      <form
                        className="login-form mt-4"
                      >
                        <Row>
                          <Col lg={12}>
                            {data.selectedcountry && settings.mobileLogin && countries && countries.length > 0 ?
                              <CountrySelect
                                countries={countries}
                                label={t('select_country')}
                                value={data.selectedcountry}
                                onChange={onCountryChange}
                                style={{ paddingTop: 20 }}
                                disabled={data.verificationId ? true : false}
                                readOnly={!settings.AllowCountrySelection}

                              />
                              : null}
                          </Col>

                          <Col lg={12}>
                            <div className="mb-3">
                              <CustomInput
                                labelText={settings.emailLogin && settings.mobileLogin ? t('contact_placeholder') : settings.emailLogin && !settings.mobileLogin ? t('email_id') : t('mobile_number')}
                                id="contact"
                                formControlProps={{
                                  fullWidth: true
                                }}
                                inputProps={{
                                  required: true,
                                  disabled: data.verificationId ? true : false,
                                  endAdornment: (
                                    <InputAdornment position="start">
                                      <AccountBoxIcon className={classes.inputIconsColor} />
                                    </InputAdornment>
                                  )
                                }}
                                onChange={onInputChange}
                                value={data.contact}
                              />
                            </div>
                          </Col>

                          <Col lg={12}>
                            {(data.contact && isNaN(data.contact)) || (settings.emailLogin && !settings.mobileLogin) ?
                              <CustomInput
                                labelText={((data.contact && isNaN(data.contact)) || (settings.emailLogin && !settings.mobileLogin)) ? t('password') : t('otp_here')}
                                id="otp"
                                formControlProps={{
                                  fullWidth: true
                                }}
                                inputProps={{
                                  type: "password",
                                  required: true,
                                  endAdornment: (
                                    <InputAdornment position="start">
                                      <Icon className={classes.inputIconsColor}>
                                        lock_outline
                                      </Icon>
                                    </InputAdornment>
                                  ),
                                  autoComplete: "off"
                                }}
                                onChange={onInputChange}
                                value={data.otp}
                              />
                              : null}
                          </Col>

                          <Col lg={12}>
                            <div className="mb-3">
                              {data.verificationId ?
                                <CustomInput
                                  labelText={t('otp')}
                                  id="otp"
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                  inputProps={{
                                    type: "password",
                                    required: true,
                                    endAdornment: (
                                      <InputAdornment position="start">
                                        <Icon className={classes.inputIconsColor}>
                                          lock_outline
                                        </Icon>
                                      </InputAdornment>
                                    ),
                                    autoComplete: "off"
                                  }}
                                  onChange={onInputChange}
                                  value={data.otp}
                                />
                                : null}
                            </div>
                          </Col>

                          <Col lg={12}>
                            <div className="d-flex justify-content-between">
                              <div className="mb-3">
                                {/* <div className="form-check">
                              <Input
                                type="checkbox"
                                className="form-check-input"
                                id="flexCheckDefault"
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="flexCheckDefault"
                              >
                                Remember me
                              </Label>
                            </div> */}
                              </div>
                              <p className="forgot-pass mb-3">

                                {data.contact && isNaN(data.contact) ?
                                  <Link
                                    simple
                                    className="text-dark fw-bold"
                                    onClick={() => forgotPassword()}
                                  >
                                    {t('forgot_password')}
                                  </Link>
                                  // <Button className={classes.normalButton} simple color="primary" size="lg" onClick={() => forgotPassword()}>
                                  //   {t('forgot_password')}
                                  // </Button>
                                  : null}
                              </p>
                            </div>
                          </Col>
                          <Col lg={12} className="mb-0">
                            <div className="d-grid">
                              {/* <Button color="primary">
                            Sign in
                          </Button>  */}
                              {isLoading ?
                                <DialogActions style={{ justifyContent: 'center', alignContent: 'center' }}>
                                  <CircularProgress />
                                </DialogActions>
                                :
                                !data.verificationId ?
                                  <Button color="primary" simple type="submit" onClick={handleGetOTP}>
                                    {data.contact && isNaN(data.contact) ? t('login') : t('login_otp')}
                                  </Button>
                                  :
                                  <>
                                      <Button color="primary" simple type="submit" onClick={handleVerifyOTP}>
                                        {t('verify_otp')}
                                      </Button>
                                      <Button className="mt-3" color="secondary" simple onClick={handleCancel}>
                                        {t('cancel')}
                                      </Button>
                                  </>
                              }
                            </div>
                          </Col>

                          {settings && settings.socialLogin ?
                            <Col md={12} className="mt-4 text-center">
                              <Row>
                                <Col className="col-12 mt-3">
                                  <div className="d-grid">
                                    <Row>
                                      <div className="col-12 mt-3">
                                        <div className="d-grid">
                                          {/* <Link to="#" className="btn btn-light"><i className="mdi mdi-google text-danger"></i> Google</Link> */}
                                          <Link
                                            justIcon
                                            href="#pablo"
                                            target="_blank"
                                            color="transparent"
                                          >
                                            <GoogleLogin
                                              onSuccess={handleGoogleLogin}
                                              onError={handleGoogleLogin}
                                            />
                                          </Link>
                                        </div>
                                      </div>
                                    </Row>
                                  </div>
                                </Col>
                              </Row>
                            </Col> : null}

                          {
                            !isLoading ?
                              <Row>
                                <Col xs={12} className="text-center">
                                  <p className="mb-0 mt-3">
                                    <small className="text-dark me-2">
                                      {t('No_Account')}
                                    </small>{" "}
                                    <Link
                                      onClick={handleShowSignup}
                                      className="text-dark fw-bold"
                                      color="primary"
                                      simple
                                      type="submit"
                                    >
                                      {t('signup')}
                                    </Link>
                                  </p>
                                </Col>
                              </Row>
                              : null
                          }
                        </Row>
                      </form>
                    </>

                  }


                </CardBody>
              </Card>
            </Col>
          </Row>
          <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>{commonAlert.msg}</AlertDialog>

        </Container>
      </section>

    </React.Fragment>
  );
};
