import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

//Import Icons
import FeatherIcon from "feather-icons-react";

class FooterWithoutMenuLightSocialOnly extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: props.settings,
    };
  }

  render() {
    return (
      <React.Fragment>
        <footer className={"footer footer-bar " + this.props.className}>
        <div className="footer-py-30">
          <Container className="text-center">
            <Row className="align-items-center">
              <Col sm="6">
                <div className="text-sm-start">
                  <p className="mb-0">
                    © {2020}-{(new Date().getFullYear())} {this.state.settings.appName.toUpperCase()} by{" "}
                    <Link
                      to="//bint-tech.com//"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-reset"
                    >
                      {this.state.settings.CompanyName}
                    </Link>
                    .
                  </p>
                </div>
              </Col>

              <Col sm="6" className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                <ul className="list-unstyled social-icon foot-social-icon text-sm-end mb-0">
                  {this.state.settings && this.state.settings.FacebookHandle?
                  <li className="list-inline-item mb-0">
                    <Link to={this.state.settings.FacebookHandle} className="rounded">                      
                        <FeatherIcon
                          icon="facebook"
                          className="fea icon-sm fea-social"
                        />                     
                    </Link>
                  </li>:null}{" "}
                  {this.state.settings && this.state.settings.InstagramHandle ?
                  <li className="list-inline-item mb-0">
                    <Link to={this.state.settings.InstagramHandle} className="rounded">                      
                        <FeatherIcon
                          icon="instagram"
                          className="fea icon-sm fea-social"
                        />                     
                    </Link>
                  </li>:null}{" "}
                  {this.state.settings && this.state.settings.TwitterHandle ?
                  <li className="list-inline-item mb-0">
                    <Link to={this.state.settings.TwitterHandle} className="rounded">                      
                        <FeatherIcon
                          icon="twitter"
                          className="fea icon-sm fea-social"
                        />                     
                    </Link>
                  </li>:null}{" "}
                  {this.state.settings && this.state.settings.linkedinHandle ?
                  <li className="list-inline-item mb-0">
                    <Link to={this.state.settings.linkedinHandle} className="rounded">                      
                        <FeatherIcon
                          icon="linkedin"
                          className="fea icon-sm fea-social"
                        />                     
                    </Link>
                  </li> : null}                 
                </ul>
              </Col>
            </Row>
          </Container>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default FooterWithoutMenuLightSocialOnly;
