// React Basic and Bootstrap
import React, { useEffect,useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container } from "reactstrap";


//Import Icons
import FeatherIcon from "feather-icons-react";
// import images
import contactDetail from "../assets/images/contact-detail.jpg";
import Topbar from "../components/Layout/Topbar";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const PageContact = () => {
    const settings = useSelector(state => state.settingsdata.settings);
    const auth = useSelector(state => state.auth);
  const { t } = useTranslation();
  
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    if (auth.profile && auth.profile.uid) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [auth.profile]);

    useEffect(() => {
        document.body.classList = "";
        const shoppingBtn = document.querySelector(".shoppingbtn");
        if (shoppingBtn) shoppingBtn.classList.add("btn-primary");
        window.addEventListener("scroll", scrollNavigation, true);

        return () => {
            window.removeEventListener("scroll", scrollNavigation, true);
        };
    }, []);

    const scrollNavigation = () => {
        var doc = document.documentElement;
        const navBar = document.getElementById("topnav");
        var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
        if (navBar != null) {
            if (top > 80) {
                navBar.classList.add("nav-sticky");
            } else {
                navBar.classList.remove("nav-sticky");
            }
        }
    };

    const sendMail = () => {
        window.location.href = "mailto:contact@example.com";
    };

    const callNumber = () => {
        window.location.href = "tel:+152534-468-854";
    };

    return (
        <React.Fragment>
            <Topbar hasDarkTopBar={false}  t={t} loggedIn={loggedIn} />

            <section
                className="vh-100 d-flex align-items-center"
                style={{ background: `url(${contactDetail}) center center` }}
            >
                <div className="bg-overlay bg-overlay-white"></div>
                <Container>
                    <Row className="align-items-center">
                        <Col lg={{ size: 6, offset: 6 }} md={{ size: 7, offset: 5 }}>
                            <div className="title-heading mt-5 pt-4">
                                <h1 className="heading">{t("contact_us")}</h1>
                                <p className="text-dark">
                                    {t("Contact_Desc")}
                                </p>
                                {settings && settings.contact_email ?
                                    <div className="d-flex contact-detail align-items-center mt-3">
                                        <div className="icon">

                                            <FeatherIcon
                                                icon="mail"
                                                className="fea icon-m-md text-dark me-3"
                                            />

                                        </div>
                                        <div className="flex-1 content">
                                            <h5 className="title fw-bold mb-0">Email</h5>
                                                <Link
                                                    to="#"
                                                    onClick={sendMail}
                                                    className="text-primary"
                                                >
                                                    {settings.contact_email}
                                                </Link>
                                            
                                        </div>
                                    </div> : ""}

                                    {settings && settings.CompanyPhone ?

                                <div className="d-flex contact-detail align-items-center mt-3">
                                    <div className="icon">

                                        <FeatherIcon
                                            icon="phone"
                                            className="fea icon-m-md text-dark me-3"
                                        />

                                    </div>
                                    <div className="flex-1 content">
                                        <h5 className="title fw-bold mb-0">Phone</h5>
                                        <Link
                                            to="#"
                                            onClick={callNumber}
                                            className="text-primary"
                                        >
                                            {settings.CompanyPhone}
                                        </Link>
                                    </div>
                                </div>:""}

                                <div className="d-flex contact-detail align-items-center mt-3">
                                    <div className="icon">

                                        <FeatherIcon
                                            icon="map-pin"
                                            className="fea icon-m-md text-dark me-3"
                                        />

                                    </div>
                                    <div className="flex-1 content">
                                        <h5 className="title fw-bold mb-0">Adresse</h5>
                                        <p className="video-play-icon text-primary">
                                        {settings.CompanyAddress}
                                        </p>
                                    </div>
                                </div>

                                <ul className="list-unstyled social-icon mb-0 mt-4">
                                {settings && settings.FacebookHandle ?
                                    <li className="list-inline-item">
                                        <Link to={settings.FacebookHandle} className="rounded">

                                            <FeatherIcon
                                                icon="facebook"
                                                className="fea icon-sm fea-social"
                                            />

                                        </Link>
                                    </li>:null}{" "}
                                    {settings && settings.InstagramHandle ?
                                    <li className="list-inline-item">
                                        <Link to={settings.InstagramHandle} className="rounded">

                                            <FeatherIcon
                                                icon="instagram"
                                                className="fea icon-sm fea-social"
                                            />

                                        </Link>
                                    </li>:null}{" "}
                                    {settings && settings.TwitterHandle ?
                                    <li className="list-inline-item">
                                        <Link to={settings.TwitterHandle} className="rounded">

                                            <FeatherIcon
                                                icon="twitter"
                                                className="fea icon-sm fea-social"
                                            />

                                        </Link>
                                    </li>:null}{" "}
                                    
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

        </React.Fragment>
    );
}
export default PageContact;
