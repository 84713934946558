// React Basic and Bootstrap
import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

//Import Images

import appImg from "../assets/images/app/app.png";
import playstoreImg from "../assets/images/app/playstore.png";

const Testi = ()=> {
  const { t } = useTranslation();
  const settings = useSelector(state => state.settingsdata.settings);
  
    return (
      <React.Fragment>
        <section className="section pt-0">
          <Container>
            <Row className="mt-md-5 pt-md-3 mt-4 pt-2 mt-sm-0 pt-sm-0 justify-content-center">
              <Col xs="12" className="text-center">
                <div className="section-title">
                  <h4 className="title mb-4">{t("mobile_apps_on_store")}</h4>
                  <p className="text-muted para-desc mx-auto">
                    {t("Store_Desc")}
                  </p>
                  <div className="mt-4">
                    <Link to={settings.AppleStoreLink}>
                      <img src={appImg} className="m-1" height="50" alt="" />
                    </Link>{" "}
                    <Link to={settings.PlayStoreLink}>
                      <img src={playstoreImg} className="m-1" height="50" alt="" />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
export default Testi;
