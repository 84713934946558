import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block'; // Importer une icône d'interdiction

function AccessDenied() {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      textAlign: 'center',
      backgroundColor: '#f9f9f9',
      padding: 3,
    }}>
      <BlockIcon sx={{ fontSize: 80, color: '#d32f2f' }} />
      <Typography variant="h4" color="error" sx={{ marginTop: 2 }}>
        Accès refusé
      </Typography>
      <Typography variant="body1" sx={{ marginTop: 1, color: '#555' }}>
        Vous n'avez pas les permissions nécessaires pour voir cette page.
      </Typography>
      <Button 
        variant="contained" 
        color="primary" 
        sx={{ marginTop: 4 }} 
        href="/"
      >
        Retour à l'accueil
      </Button>
    </Box>
  );
}

export default AccessDenied;
